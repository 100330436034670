import DIMENSIONS from "creators/constants/dimensions";
import { forwardRef, HTMLAttributes } from "react";
import useFeature from "shared/hooks/useFeature";
import styled from "styled-components";

const Modal = styled.div<{ vui?: boolean }>`
  align-self: center;
  background: ${({ vui }) =>
    vui ? "rgb(var(--vui-color-surface-primary))" : "var(--v-color-white)"};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-height: 100%;
  overflow: auto;
  padding: ${({ vui }) =>
    vui ? "var(--vui-spacing-4)" : "var(--v-spacing-4)"};

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: ${({ vui }) =>
      vui ? "var(--vui-spacing-8)" : "var(--v-spacing-8)"};
  }

  &:focus {
    outline: none;
  }
`;

const StandardModal = forwardRef(
  (
    {
      children,
      className,
    }: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>,
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const creatorsVUI = useFeature("creatorsVUI");

    return (
      <Modal className={className} ref={ref} vui={creatorsVUI}>
        {children}
      </Modal>
    );
  },
);

export default StandardModal;
