import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavDrawerItem from "vui/components/NavDrawerItem";
import NavDrawerLink from "vui/components/NavDrawerLink";
import { NavRailItem } from "vui/components/NavRail";
import NavRailLink from "vui/components/NavRailLink";
import VisuallyHidden from "vui/components/VisuallyHidden";

const StyledNavRailButton = styled(NavRailLink)`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const StyledNavDrawerButton = styled(NavDrawerLink)`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;

  & span {
    text-align: left;
  }
`;

export interface IProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  href?: string;
  icon: React.FC;
  iconFilled?: React.FC;
  id?: string;
  label?: ReactNode | string;
  mobile?: boolean;
  notification?: boolean;
  onClick?: () => void;
  rel?: string;
  showActiveIcon?: boolean;
  target?: string;
  to?: string;
}

const NavItem: React.FC<React.PropsWithChildren<IProps>> = ({
  active,
  className,
  hideLabel,
  href,
  id,
  icon,
  iconFilled,
  label,
  mobile,
  notification,
  onClick,
  rel,
  target,
  to,
}) => {
  const sharedProps = {
    className,
    id,
    onClick,
  };

  const ItemComponent = mobile ? NavDrawerItem : NavRailItem;
  const LinkComponent = mobile ? NavDrawerLink : NavRailLink;
  const ButtonComponent = mobile ? StyledNavDrawerButton : StyledNavRailButton;

  const content = (
    <ItemComponent
      active={active}
      icon={active && iconFilled ? iconFilled : icon}
      notification={notification}
    >
      {hideLabel ? <VisuallyHidden>{label}</VisuallyHidden> : label}
    </ItemComponent>
  );

  if (to) {
    return (
      <LinkComponent
        as={Link}
        {...sharedProps}
        $active={active}
        rel={rel}
        target={target}
        to={to}
      >
        {content}
      </LinkComponent>
    );
  }

  if (href) {
    return (
      <LinkComponent
        {...sharedProps}
        $active={active}
        href={href}
        rel={rel}
        target={target}
      >
        {content}
      </LinkComponent>
    );
  }

  if (onClick) {
    return (
      <ButtonComponent as="button" {...sharedProps}>
        {content}
      </ButtonComponent>
    );
  }

  return content;
};

export default NavItem;
