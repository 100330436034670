import { SecondaryButton } from "creators/components/Button";
import CloseIcon from "creators/images/icons/cross.svg";
import textualize from "creators/utils/textualize";
import { ReactNode } from "react";
import Icon from "shared/components/Icon";
import styled from "styled-components";

export interface IProps {
  className?: string;
  icon?: ReactNode;
  id?: string;
  label?: string | ReactNode;
  onClick: () => void;
  vui?: boolean;
}

// TODO: Remove creatorsVUI prop when vui modal component is created
const StyledButton = styled(SecondaryButton)<{ creatorsVUI?: boolean }>`
  background: var(--v-color-white);
  border: none;
  color: var(--v-color-black);
  padding: 0.875rem;
  width: auto;

  :active {
    border: none;
  }

  ${({ creatorsVUI }) =>
    creatorsVUI && "background: rgb(var(--vui-color-surface-primary));"}
`;

const StyledIcon = styled(Icon)`
  display: block;
`;

export const NavigationButton: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  icon = CloseIcon,
  id,
  label = textualize("common.close"),
  onClick,
  vui,
}) => (
  <StyledButton
    className={className}
    creatorsVUI={vui}
    id={id}
    onClick={onClick}
  >
    <StyledIcon component={icon} label={label} />
  </StyledButton>
);

export default NavigationButton;
