import styled from "styled-components";

export const NavDrawerContainer = styled.aside`
  background: rgb(var(--vui-color-surface-secondary));
  border-radius: 0 var(--vui-shape-corner-large) var(--vui-shape-corner-large) 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  will-change: transform;
  z-index: 3;
`;

export const NavDrawerContent = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: var(--vui-spacing-2) 0 0;
  overflow-y: auto;
  width: var(--vui-spacing-80);
`;

export const NavDrawerWrapper = styled.div`
  margin: 0 var(--vui-spacing-2);
`;
