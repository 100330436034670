import { useQuery } from "@apollo/client";
import GlobalNav from "creators/components/vui/GlobalNav";
import UserNav from "creators/components/vui/UserNav";
import { GET_VIEWER } from "creators/queries/GetViewer";
import { GetViewerQuery as IGetViewer } from "creators/types/graphql";
import {
  NavRailContainer,
  NavRailContent,
  NavRailFooter,
  NavRailHeader,
} from "vui/components/NavRail";
import VidsyMonogram from "vui/icons/vidsy-monogram.svg";

const DesktopNav: React.FC = () => {
  const { data: viewerData } = useQuery<IGetViewer>(GET_VIEWER);

  return (
    <NavRailContainer>
      <NavRailContent>
        <NavRailHeader>
          <VidsyMonogram />
        </NavRailHeader>

        <GlobalNav />
      </NavRailContent>

      <NavRailFooter>
        <UserNav viewerData={viewerData} />
      </NavRailFooter>
    </NavRailContainer>
  );
};

export default DesktopNav;
