import styled, { css } from "styled-components";
import { NavDrawerItemOuter } from "vui/components/NavDrawerItem/styles";

interface IProps {
  $active?: boolean;
}

const NavDrawerLink = styled.a.attrs<IProps>(({ $active }) => ({
  "aria-current": $active ? "page" : undefined,
}))<IProps>`
  color: rgb(var(--vui-color-on-surface-secondary));
  display: block;

  &:focus-visible,
  &:hover {
    color: rgb(var(--vui-color-on-surface-tertiary));

    ${NavDrawerItemOuter} {
      &::before {
        background-color: rgb(var(--vui-color-surface-tertiary));
        opacity: 1;
      }
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: rgb(var(--vui-color-inverse-on-surface));

      ${NavDrawerItemOuter} {
        &::before {
          background-color: rgb(var(--vui-color-inverse-surface));
          opacity: 1;
        }
      }

      &:focus-visible,
      &:hover {
        color: rgb(var(--vui-color-active-on-surface));

        ${NavDrawerItemOuter} {
          &::before {
            background-color: rgb(var(--vui-color-active-surface));
          }
        }
      }
    `}
`;

export default NavDrawerLink;
