export default {
  ACHIEVEMENTS_FEEDBACK: "https://vidsyhq.typeform.com/achievements",
  AE_PROJECT_SETUP:
    "https://help.creator.vidsy.co/en/articles/10411591-a-guide-to-working-with-adobe-after-effects-at-vidsy",
  CGC_DELIVERY:
    "https://help.creator.vidsy.co/en/articles/10429711-how-do-i-submit-cgc",
  CREATOR_HUB: "https://community.vidsy.co/",
  CREATOR_NOT_SELECTED:
    "https://help.creator.vidsy.co/en/articles/1731483-why-wasn-t-i-selected-for-a-brief",
  DELIVERY:
    "https://help.creator.vidsy.co/en/articles/10353771-how-do-i-submit-my-content",
  HELP: "https://help.creator.vidsy.co/",
  HOW_PAYMENTS_WORK:
    "https://help.creator.vidsy.co/en/articles/3725569-how-our-payments-work-with-wise",
  PAYMENT_CURRENCY:
    "https://help.creator.vidsy.co/en/articles/5394299-which-currency-should-i-select-for-my-payment",
  PRIVACY_POLICY: "https://vidsy.co/privacypolicy/",
  TERMS_AND_CONDITIONS: "https://vidsy.co/tcs/",
  VIDSY_RECOMMENDED:
    "https://help.creator.vidsy.co/en/articles/5598829-what-does-vidsy-recommended-mean",
};
