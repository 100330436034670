import { CopyDefinition } from "@creator-types/config";
import achievements from "./achievements.yml";
import analytics from "./analytics.yml";
import blocks from "./blocks.yml";
import brief from "./brief.yml";
import briefActions from "./briefActions.yml";
import briefCard from "./briefCard.yml";
import briefReleaseForms from "./briefReleaseForms.yml";
import briefSubmit from "./briefSubmit.yml";
import briefSummaryHeader from "./briefSummaryHeader.yml";
import carousel from "./carousel.yml";
import changePassword from "./changePassword.yml";
import common from "./common.yml";
import components from "./components.yml";
import error from "./error.yml";
import liveBriefs from "./liveBriefs.yml";
import maintenanceMode from "./maintenanceMode.yml";
import modals from "./modals.yml";
import nav from "./nav.yml";
import notFound from "./notFound.yml";
import onboarding from "./onboarding.yml";
import payments from "./payments.yml";
import previousBrief from "./previousBrief.yml";
import previousBriefs from "./previousBriefs.yml";
import profile from "./profile.yml";
import releaseForms from "./releaseForms.yml";
import resetPassword from "./resetPassword.yml";
import settings from "./settings.yml";
import skills from "./skills.yml";
import specifications from "./specifications.yml";
import time from "./time.yml";
import unauthenticated from "./unauthenticated.yml";
import upload from "./upload.yml";
import video from "./video.yml";

import vuiBriefActions from "./vui/briefActions.yml";
import vuiBriefSubmit from "./vui/briefSubmit.yml";
import vuiCommon from "./vui/common.yml";
import vuiError from "./vui/error.yml";
import vuiLiveBriefs from "./vui/liveBriefs.yml";
import vuiMaintenanceMode from "./vui/maintenanceMode.yml";
import vuiNav from "./vui/nav.yml";
import vuiNotFound from "./vui/notFound.yml";
import vuiOnboarding from "./vui/onboarding.yml";
import vuiPayments from "./vui/payments.yml";
import vuiProfile from "./vui/profile.yml";
import vuiReleaseForms from "./vui/releaseForms.yml";
import vuiSettings from "./vui/settings.yml";
import vuiUnauthenticated from "./vui/unauthenticated.yml";
import vuiUpload from "./vui/upload.yml";

const copy: CopyDefinition = {
  achievements,
  analytics,
  blocks,
  brief,
  briefActions,
  briefCard,
  briefReleaseForms,
  briefSubmit,
  briefSummaryHeader,
  changePassword,
  carousel,
  common,
  components,
  error,
  liveBriefs,
  maintenanceMode,
  modals,
  nav,
  notFound,
  onboarding,
  payments,
  previousBrief,
  previousBriefs,
  profile,
  releaseForms,
  resetPassword,
  settings,
  skills,
  specifications,
  time,
  unauthenticated,
  upload,
  video,
  vuiBriefActions,
  vuiBriefSubmit,
  vuiCommon,
  vuiError,
  vuiLiveBriefs,
  vuiMaintenanceMode,
  vuiNav,
  vuiNotFound,
  vuiOnboarding,
  vuiPayments,
  vuiReleaseForms,
  vuiProfile,
  vuiSettings,
  vuiUpload,
  vuiUnauthenticated,
};

export default copy;
