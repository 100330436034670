import styled, { css } from "styled-components";
import {
  NavRailContentItemIcon,
  NavRailContentItemImage,
} from "../NavRail/styles";

interface IProps {
  $active?: boolean;
}

const NavRailLink = styled.a.attrs<IProps>(({ $active }) => ({
  "aria-current": $active ? "page" : undefined,
}))<IProps>`
  display: block;
  margin: 0 var(--vui-spacing-0-5) var(--vui-spacing-3);

  &:focus-visible,
  &:hover {
    color: rgb(var(--vui-color-on-surface-tertiary));

    ${NavRailContentItemIcon},
    ${NavRailContentItemImage} {
      &::before {
        background-color: rgb(var(--vui-color-surface-tertiary));
        opacity: 1;
      }
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  ${({ $active }) =>
    $active &&
    css`
      ${NavRailContentItemIcon},
      ${NavRailContentItemImage} {
        color: rgb(var(--vui-color-inverse-on-surface));

        &::before {
          background-color: rgb(var(--vui-color-inverse-surface));
          opacity: 1;
        }
      }

      &:focus-visible,
      &:hover {
        ${NavRailContentItemIcon},
        ${NavRailContentItemImage} {
          color: rgb(var(--vui-color-active-on-surface));

          &::before {
            background-color: rgb(var(--vui-color-active-surface));
          }
        }
      }
    `}
`;

export default NavRailLink;
