export const rem = 16;

export const PX = {
  BREAKPOINT_XS: 0,
  BREAKPOINT_SM: 600,
  BREAKPOINT_MD: 905,
  BREAKPOINT_LG: 1280,
  BREAKPOINT_XL: 1440,
};

export const REM = {
  BREAKPOINT_XS: `${PX.BREAKPOINT_XS / rem}rem`,
  BREAKPOINT_SM: `${PX.BREAKPOINT_SM / rem}rem`,
  BREAKPOINT_MD: `${PX.BREAKPOINT_MD / rem}rem`,
  BREAKPOINT_LG: `${PX.BREAKPOINT_LG / rem}rem`,
  BREAKPOINT_XL: `${PX.BREAKPOINT_XL / rem}rem`,
};

export default REM;
