import SessionExpiredModal from "creators/components/SessionExpiredModal";
import SessionExpiredModalVUI from "creators/components/vui/SessionExpiredModal";
import IDS from "creators/constants/ids";
import Session from "creators/services/Session";
import { useCallback, useEffect, useState } from "react";
import useInterval from "react-useinterval";
import useFeature from "shared/hooks/useFeature";
import useModal from "shared/hooks/useModal";

export const CHECK_INTERVAL = 1000 * 60; // Check every minute

const SessionDetails = () => {
  const creatorsVUIFlag = useFeature("creatorsVUI");

  const [expired, setExpired] = useState(false);

  const { openModal } = useModal(IDS.MODALS.SESSION_EXPIRED.ID);

  const checkExpiry = useCallback(() => {
    if (!Session.isAuthenticated()) {
      setExpired(true);
    }
  }, []);

  useEffect(() => {
    if (expired) {
      if (creatorsVUIFlag) {
        openModal(<SessionExpiredModalVUI />);
      } else {
        openModal(<SessionExpiredModal />);
      }
    }
  }, [creatorsVUIFlag, expired, openModal]);

  useEffect(() => checkExpiry(), [checkExpiry]); // Check on load...
  useInterval(() => checkExpiry(), !expired ? CHECK_INTERVAL : null); // ...and periodically

  return null;
};

export default SessionDetails;
