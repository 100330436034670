import StandardModal from "creators/components/Modal/StandardModal";
import styled from "styled-components";

export const StyledStandardModal = styled(StandardModal)`
  background: rgb(var(--vui-color-surface-primary));
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);
  min-width: 33vw;
  padding: var(--vui-spacing-6);
  width: 100%;
  z-index: 100;
`;

export const Heading = styled.h2`
  font-size: var(--vui-typescale-title-large-size);
  font-weight: var(--vui-typescale-title-large-weight);
  line-height: var(--vui-typescale-title-large-line-height);
`;

export const Message = styled.div`
  font-size: var(--vui-typescale-body-medium-size);
  font-weight: var(--vui-typescale-body-medium-weight);
  line-height: var(--vui-typescale-body-medium-line-height);
  margin-bottom: var(--vui-spacing-4);
`;
