import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: React.FunctionComponent;
}

export function FilledButton({ children, icon, ...rest }: IProps) {
  const IconComponent = icon;
  return (
    <BaseButton hasIcon={!!icon} {...rest}>
      {IconComponent && (
        <IconContainer>
          <IconComponent />
        </IconContainer>
      )}
      {/* span guards against Google Translate crashes */}
      <span>{children}</span>
    </BaseButton>
  );
}

type TBaseButton = {
  hasIcon?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const BaseButton = styled.button<TBaseButton>`
  align-items: center;
  appearance: none;
  background: rgb(var(--vui-color-inverse-surface));
  border: none;
  border-radius: var(--vui-shape-corner-full);
  color: rgb(var(--vui-color-inverse-on-surface));
  cursor: pointer;
  display: inline-flex;
  font-family: var(--vui-typescale-label-large-font);
  font-size: var(--vui-typescale-label-large-size);
  font-weight: var(--vui-typescale-label-large-weight);
  height: var(--vui-spacing-10);
  letter-spacing: var(--vui-typescale-label-large-tracking);
  line-height: var(--vui-typescale-label-large-line-height);
  padding: 0 var(--vui-spacing-6) 0
    ${({ hasIcon }) =>
      hasIcon ? "var(--vui-spacing-4)" : "var(--vui-spacing-6)"};
  text-align: center;

  &:focus,
  &:hover {
    background-color: rgb(var(--vui-color-active-surface));
    color: rgb(var(--vui-color-active-on-surface));
  }

  &:focus {
    outline: rgb(var(--vui-color-active-surface-outline)) solid
      var(--vui-state-focus-indicator-thickness);
    outline-offset: var(--vui-state-focus-indicator-outer-offset);
  }

  &:disabled {
    background-color: rgb(var(--vui-color-disabled-surface));
    color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    cursor: default;

    &:focus,
    &:hover {
      background-color: rgb(var(--vui-color-disabled-surface));
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    }
  }

  &:active {
    background-color: rgb(var(--vui-color-active-surface));
  }
`;

export const TextButton = styled(FilledButton)`
  background: none;
  color: rgb(var(--vui-color-on-surface-tertiary));

  &:active,
  &:focus,
  &:hover {
    background-color: rgb(var(--vui-color-surface-tertiary));
    color: rgb(var(--vui-color-on-surface-tertiary));
  }

  &:disabled {
    background-color: none;
    color: rgba(var(--vui-color-disabled-on-surface), 0.5);

    &:active,
    &:focus,
    &:hover {
      background-color: none;
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    }
  }
`;

export const OutlinedButton = styled(FilledButton)`
  background: none;
  color: rgb(var(--vui-color-on-surface-tertiary));
  outline: var(--vui-spacing-0-5) solid
    rgb(var(--vui-color-on-surface-tertiary));

  &:active,
  &:focus,
  &:hover {
    background-color: rgb(var(--vui-color-surface-tertiary));
    color: rgb(var(--vui-color-on-surface-tertiary));
  }

  &:disabled {
    background-color: none;
    color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    outline-color: rgba(var(--vui-color-disabled-on-surface-outline), 0.5);

    &:active,
    &:focus,
    &:hover {
      background-color: none;
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    }
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  height: var(--vui-spacing-6);
  line-height: var(--vui-spacing-6);
  margin-right: var(--vui-spacing-1);
  width: var(--vui-spacing-6);

  svg {
    height: var(--vui-spacing-4);
    margin: auto;
    width: var(--vui-spacing-4);
  }
`;
