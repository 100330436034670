import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { addSnackbarAction, removeSnackbarAction } from "vui/actions/snackbars";
import SNACKBAR from "vui/constants/actions/snackbar";
import { IAction } from "vui/types/actions";

export const DEFAULT_SNACKBAR_EXPIRY = 3000;

export type ISnackbarState = ISnackbar[];

export type AddSnackbar = (message: string) => void;
export type RemoveSnackbar = (id: string) => void;

interface IProps {
  children?: ReactNode;
}

export interface ISnackbar {
  id: string;
  message: string;
}

interface IContext {
  addSnackbar: AddSnackbar;
  removeSnackbar?: RemoveSnackbar;
  snackbars: ISnackbar[];
}

export const SnackbarContext = createContext<IContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addSnackbar: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeSnackbar: () => {},
  snackbars: [],
});

export const snackbarReducer = (state: ISnackbarState, action: IAction) => {
  switch (action.type) {
    case SNACKBAR.ADD: {
      return [
        ...state,
        {
          id: action.payload.id,
          message: action.payload.message,
        },
      ];
    }
    case SNACKBAR.REMOVE: {
      return state.filter((snackbar) => snackbar.id !== action.payload);
    }
    default:
      break;
  }
  return state;
};

export const SnackbarProvider = ({ children }: IProps) => {
  const [snackbarState, dispatch] = useReducer(snackbarReducer, []);
  const [currentSnackbar, setCurrentSnackbar] = useState<ISnackbar | null>(
    null,
  );
  const [snackbarTimeout, setSnackbarTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const addSnackbar = useCallback(
    (message: string) => {
      const id = new Date().toISOString();
      dispatch(addSnackbarAction(id, message));
    },
    [dispatch],
  );

  const removeSnackbar = useCallback(
    (id: string) => {
      dispatch(removeSnackbarAction(id));
      setCurrentSnackbar(null);
      if (snackbarTimeout) {
        clearTimeout(snackbarTimeout);
        setSnackbarTimeout(null);
      }
    },
    [dispatch, snackbarTimeout],
  );

  useEffect(() => {
    if (!currentSnackbar && snackbarState.length > 0) {
      const [nextSnackbar] = snackbarState;
      setCurrentSnackbar(nextSnackbar);

      const timeout = setTimeout(() => {
        removeSnackbar(nextSnackbar.id);
      }, DEFAULT_SNACKBAR_EXPIRY);

      setSnackbarTimeout(timeout);
    }
  }, [currentSnackbar, snackbarState, removeSnackbar]);

  return (
    <SnackbarContext.Provider
      value={{
        addSnackbar,
        removeSnackbar,
        snackbars: currentSnackbar ? [currentSnackbar] : [],
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
