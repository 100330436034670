import SNACKBAR from "vui/constants/actions/snackbar";

export const addSnackbarAction = (id: string, message: string) => ({
  payload: { id, message },
  type: SNACKBAR.ADD,
});

export const removeSnackbarAction = (id: string) => ({
  payload: id,
  type: SNACKBAR.REMOVE,
});
