import {
  NavDrawerContainer,
  NavDrawerContent,
  NavDrawerWrapper,
} from "./styles";

interface IProps {
  children?: React.ReactNode;
  style?: {
    // React spring animation styles
    transform: string;
  };
}

const NavDrawer: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  style,
}) => {
  return (
    <NavDrawerContainer style={style}>
      <NavDrawerContent>
        <NavDrawerWrapper>{children}</NavDrawerWrapper>
      </NavDrawerContent>
    </NavDrawerContainer>
  );
};

export default NavDrawer;
