import IDS from "creators/constants/ids";
import {
  BriefType,
  ActiveBriefFieldsFragment as IBrief,
  SubmissionState,
} from "creators/types/graphql";
import { IHeadingLink, INavLink } from "creators/types/navLinks";
import textualize from "creators/utils/textualize";
import flattenDeep from "lodash/flattenDeep";
import { collateSections, ICollatedSection } from "shared/BlockBuilder/utils";
import generateBlockID from "shared/utils/generateBlockID";

export interface IOptions {
  briefOnly?: boolean;
  previewHash?: string;
  releaseFormsEnabled?: boolean;
  submissionState?: SubmissionState;
  vui?: boolean;
}

const generateActiveBriefSubNav = (brief: IBrief, options: IOptions = {}) => {
  const { briefOnly, previewHash, releaseFormsEnabled, submissionState, vui } =
    options;

  const linkPrefix = previewHash
    ? `/brief/${brief.id}/full/${previewHash}`
    : "/active-brief";

  const links: INavLink[] = [];

  // Intro
  const intro: INavLink = {
    name: textualize("brief.introduction"),
    to: linkPrefix,
  };

  if (brief.details.introduction) {
    let sections: ICollatedSection[] = [];

    try {
      sections = collateSections(JSON.parse(brief.details.introduction));
    } catch {
      // Keep sections empty
    }

    if (sections.length > 0) {
      const headings: IHeadingLink[] = [];

      sections.forEach((section, sectionIndex) => {
        headings.push({
          hash: generateBlockID(IDS.BRIEF.INTRODUCTION.BLOCK, sectionIndex),
          name: Array.isArray(section.section.properties.value)
            ? flattenDeep(section.section.properties.value).join("") // TODO: Replace with Array.flat once lib compiler updated
            : section.section.properties.value,
        });
      });

      // First intro section heading should always be Overview
      if (headings.length) {
        headings[0].name = textualize("brief.overview");
      }

      intro.headings = headings;
    }
  }

  if (brief.details.beforeYouStart) {
    let sections: ICollatedSection[] = [];

    try {
      sections = collateSections(JSON.parse(brief.details.beforeYouStart));
    } catch {
      // Keep sections empty
    }

    if (sections.length > 0) {
      const headings: IHeadingLink[] = [];

      const introSectionCount = intro.headings?.length || 0;

      sections.forEach((section, sectionIndex) => {
        headings.push({
          hash: generateBlockID(
            IDS.BRIEF.INTRODUCTION.BLOCK,
            introSectionCount + sectionIndex,
          ),
          name: Array.isArray(section.section.properties.value)
            ? flattenDeep(section.section.properties.value).join("") // TODO: Replace with Array.flat once lib compiler updated
            : section.section.properties.value,
        });
      });

      if (intro.headings) {
        intro.headings = intro.headings.concat(headings);
      } else {
        intro.headings = headings;
      }
    }
  }

  links.push(intro);

  // Videos
  brief.details.videoTextBlocks.forEach((video, index) => {
    const videoNumber = index + 1;

    const link: INavLink = {
      name: textualize("brief.video", {
        count: videoNumber,
      }),
      to: `${linkPrefix}/video-${videoNumber}`,
    };

    if (video.description) {
      let sections: ICollatedSection[] = [];

      try {
        sections = collateSections(JSON.parse(video.description));
      } catch {
        // Keep sections empty
      }

      const renderedSections = [...sections];

      if (renderedSections.length > 0) {
        const headings: IHeadingLink[] = [];

        headings.push({
          hash: IDS.BRIEF.VIDEOS.SUMMARY,
          name: textualize("brief.introduction"),
        });

        renderedSections.forEach((section, sectionIndex) => {
          headings.push({
            hash: generateBlockID(IDS.BRIEF.VIDEOS.BLOCK, sectionIndex),
            name:
              sectionIndex === 0
                ? textualize("brief.mustInclude")
                : Array.isArray(section.section.properties.value)
                  ? flattenDeep(section.section.properties.value).join("") // TODO: Replace with Array.flat once lib compiler updated
                  : section.section.properties.value,
          });
        });

        link.headings = headings;
      }
    }

    links.push(link);
  });

  if (briefOnly) {
    return links;
  }

  // Release forms for CGC briefs
  if (
    !previewHash &&
    releaseFormsEnabled &&
    submissionState === SubmissionState.InviteAccepted &&
    brief.type === BriefType.LiveAction
  ) {
    const releaseForms: INavLink = {
      headings: [
        {
          hash: IDS.BRIEF_RELEASE_FORMS.CONTRIBUTOR.CONTAINER,
          name: textualize("briefReleaseForms.headings.contributor"),
        },
        {
          hash: IDS.BRIEF_RELEASE_FORMS.LOCATION.CONTAINER,
          name: textualize("briefReleaseForms.headings.location"),
        },
      ],
      name: textualize("briefReleaseForms.headings.page"),
      to: `${linkPrefix}/release-forms`,
    };

    links.push(releaseForms);
  }

  if (vui && !previewHash) {
    // Submit
    const headings = brief.details.videoTextBlocks.map((video, index) => ({
      hash: `${IDS.BRIEF_SUBMIT.VIDEO_BLOCK.ID}_${index}`,
      name: `${
        textualize("specifications.position", {
          count: index + 1,
        }) as string
      }: ${video.title}`,
    }));

    if (brief.type === BriefType.MotionGraphics && !!brief.deliveryLink) {
      headings.push({
        hash: IDS.BRIEF_SUBMIT.AE_FILES.HEADING,
        name: textualize("vuiBriefSubmit.aeFiles.heading") as string,
      });
    } else if (brief.type === BriefType.LiveAction) {
      headings.push(
        {
          hash: IDS.BRIEF_RELEASE_FORMS.CONTRIBUTOR.HEADING,
          name: textualize(
            "vuiBriefSubmit.releaseForms.title.contributor",
          ) as string,
        },
        {
          hash: IDS.BRIEF_RELEASE_FORMS.LOCATION.HEADING,
          name: textualize(
            "vuiBriefSubmit.releaseForms.title.location",
          ) as string,
        },
      );

      if (brief.details.releaseFormsReturnURI) {
        headings.push({
          hash: IDS.BRIEF_SUBMIT.SUPPORTING_FILES.HEADING,
          name: textualize("vuiBriefSubmit.supportingFiles.upload") as string,
        });
      }
    }

    // Only add the final submission link if the submission state is not Invited
    if (submissionState !== SubmissionState.Invited) {
      headings.push({
        hash: IDS.BRIEF_SUBMIT.FINAL_SUBMISSION.ID,
        name: textualize("vuiBriefSubmit.finalSubmission.heading") as string,
      });

      const submission: INavLink = {
        headings,
        name: textualize("vuiBriefActions.submit"),
        to: `${linkPrefix}/submit`,
      };

      links.push(submission);
    }
  }

  return links;
};

export default generateActiveBriefSubNav;
