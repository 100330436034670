import { useQuery } from "@apollo/client";
import NavItem from "creators/components/vui/NavItem";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import { routes as homeRoutes } from "creators/pages/Home";
import { GET_ACTIVE_BRIEF } from "creators/queries/GetActiveBrief";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetActiveBriefQuery as IGetActiveBrief,
  GetViewerQuery as IGetViewer,
  SubmissionState,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import flatten from "lodash/flatten";
import { useRouteMatch } from "react-router-dom";
import VuiHomeFilledIcon from "vui/icons/filled/home.svg";
import VuiPastTaskFilledIcon from "vui/icons/filled/past-task.svg";
import VuiPaymentsFilledIcon from "vui/icons/filled/payments.svg";
import VuiTaskFilledIcon from "vui/icons/filled/task.svg";
import VuiHelpIcon from "vui/icons/help.svg";
import VuiHomeIcon from "vui/icons/home.svg";
import VuiPastTaskIcon from "vui/icons/past-task.svg";
import VuiPaymentsIcon from "vui/icons/payments.svg";
import VuiSchoolIcon from "vui/icons/school.svg";
import VuiTaskIcon from "vui/icons/task.svg";

export interface IProps {
  mobile?: boolean;
}

export interface IPreviewBriefParams {
  id: string;
  previewHash: string;
}

const GlobalNav: React.FC<IProps> = ({ mobile }) => {
  const { data: viewerData } = useQuery<IGetViewer>(GET_VIEWER);
  const { data: briefData } = useQuery<IGetActiveBrief>(GET_ACTIVE_BRIEF);
  const submission = briefData?.viewerSubmissions.edges?.[0]?.node;

  const activeBriefRoute = useRouteMatch("/active-brief");
  const previewBriefRoute = useRouteMatch<IPreviewBriefParams>(
    "/brief/:id(\\d+)/full/:previewHash",
  );

  const homeRoute = useRouteMatch({
    path: flatten(homeRoutes.map((route) => route.path)),
    exact: true,
  });
  const pastBriefsRoute = useRouteMatch("/previous-briefs");
  const paymentsRoute = useRouteMatch("/payments");

  const verifiedAndOnboarded =
    !!viewerData?.viewer &&
    viewerData.viewer.emailVerificationStage ===
      EmailVerificationStage.Verified &&
    !!viewerData.viewer.termsAndConditionsAccepted &&
    !onboardingIncomplete(viewerData.viewer);

  const isActiveBriefPage = !!activeBriefRoute || !!previewBriefRoute;

  return (
    <>
      <NavItem
        active={!!homeRoute}
        icon={VuiHomeIcon}
        iconFilled={VuiHomeFilledIcon}
        label={textualize("vuiNav.home")}
        mobile={mobile}
        to="/"
      />

      {verifiedAndOnboarded && (
        <>
          <NavItem
            active={isActiveBriefPage}
            icon={VuiTaskIcon}
            iconFilled={VuiTaskFilledIcon}
            label={textualize("vuiNav.activeBrief")}
            mobile={mobile}
            notification={submission?.state === SubmissionState.Invited}
            to={verifiedAndOnboarded ? "/active-brief" : undefined}
          />
          <NavItem
            active={!!pastBriefsRoute}
            icon={VuiPastTaskIcon}
            iconFilled={VuiPastTaskFilledIcon}
            label={textualize("vuiNav.pastBriefs")}
            mobile={mobile}
            to={verifiedAndOnboarded ? "/previous-briefs" : undefined}
          />
          <NavItem
            active={!!paymentsRoute}
            icon={VuiPaymentsIcon}
            iconFilled={VuiPaymentsFilledIcon}
            label={textualize("vuiNav.payments")}
            mobile={mobile}
            to={verifiedAndOnboarded ? "/payments" : undefined}
          />
        </>
      )}
      <NavItem
        href={EXTERNAL_LINKS.CREATOR_HUB}
        icon={VuiSchoolIcon}
        label={textualize("vuiNav.creatorHub")}
        mobile={mobile}
        rel="noopener"
        target="_blank"
      />
      <NavItem
        href={EXTERNAL_LINKS.HELP}
        icon={VuiHelpIcon}
        label={textualize("vuiNav.help")}
        mobile={mobile}
      />
    </>
  );
};

export default GlobalNav;
