import NavigationButton from "creators/components/NavigationButton";
import OverlayBackdrop from "creators/components/OverlayBackdrop";
import DIMENSIONS from "creators/constants/dimensions";
import { forwardRef, ReactNode } from "react";
import ReactDom from "react-dom";
import useFeature from "shared/hooks/useFeature";
import styled from "styled-components";

const StyledModalWrapper = styled.div<{ creatorsVUI?: boolean }>`
  align-items: center;
  display: flex;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
  padding: ${({ creatorsVUI }) =>
    creatorsVUI ? "var(--vui-spacing-2)" : "var(--v-spacing-2)"};
  position: fixed;
  top: 0;
  width: 100vw;
  width: 100dvw;
  z-index: 100;
`;

const Inner = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  z-index: 3;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    align-items: flex-start;
    flex-direction: row;
    margin-right: 3.75rem; // Centre modal by offsetting close
    max-width: calc(100% - 7.5rem);
  }
`;
interface IProps {
  children: ReactNode;
  className?: string;
  id?: string;
  onClose?: () => void;
}

export const ModalWrapper = forwardRef(
  (
    { children, className, id, onClose }: IProps,
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const creatorsVUI = useFeature("creatorsVUI");

    const node = (
      <StyledModalWrapper
        className={className}
        creatorsVUI={creatorsVUI}
        id={id}
        ref={ref}
      >
        <OverlayBackdrop onClick={onClose} vui={creatorsVUI} />

        <Inner>
          {onClose && <NavigationButton onClick={onClose} vui={creatorsVUI} />}

          {children}
        </Inner>
      </StyledModalWrapper>
    );
    return ReactDom.createPortal(
      node,
      document.getElementById("modal-root") as Element,
    );
  },
);

export default ModalWrapper;
