import Avatar from "creators/components/Avatar";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { NavRailItem } from "vui/components/NavRail";
import NavRailLink from "vui/components/NavRailLink";
import VisuallyHidden from "vui/components/VisuallyHidden";

export interface IProps {
  active?: boolean;
  creatorID: string;
  creatorImage?: string;
  hideLabel?: boolean;
  id?: string;
  label?: ReactNode | string;
  rel?: string;
  target?: string;
  to: string;
}

// TODO: Currently uses old Avatar component
const NavProfileItem: React.FC<IProps> = ({
  active,
  creatorID,
  creatorImage,
  hideLabel,
  id,
  label,
  rel,
  target,
  to,
}) => {
  return (
    <NavRailLink
      $active={active}
      as={Link}
      id={id}
      rel={rel}
      target={target}
      to={to}
    >
      <NavRailItem
        image={<Avatar alt="" avatarID={creatorID} src={creatorImage} />}
      >
        {hideLabel ? <VisuallyHidden>{label}</VisuallyHidden> : label}
      </NavRailItem>
    </NavRailLink>
  );
};

export default NavProfileItem;
