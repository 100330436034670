import {
  default as BaseSnackbar,
  IProps as ISnackbar,
} from "vui/components/Snackbar";

export interface IProps extends ISnackbar {
  message: string;
}

const Snackbar = ({ className, message, onClick, style }: IProps) => (
  <BaseSnackbar className={className} onClick={onClick} style={style}>
    {message}
  </BaseSnackbar>
);

export default Snackbar;
