import {
  NavDrawerItemIcon,
  NavDrawerItemLabel,
  NavDrawerItemOuter,
} from "./styles";

interface IProps {
  children: React.ReactNode;
  icon: React.FC;
}

const NavDrawerItem: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  icon,
}) => {
  return (
    <NavDrawerItemOuter>
      <NavDrawerItemIcon component={icon} />
      <NavDrawerItemLabel>{children}</NavDrawerItemLabel>
    </NavDrawerItemOuter>
  );
};

export default NavDrawerItem;
